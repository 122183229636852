import React from 'react';
import { graphql } from 'gatsby';
import { CategoryContainer } from 'containers/category/Category';
import { fragmentMeta, fragmentLink } from 'fragments';

interface IProps {
  data: any;
  location?: any;
}

const aboutIcelandTemplate = (props: IProps) => {
  return (
    <CategoryContainer filter="About Iceland" {...props}/>
  );
};

export const query = graphql`
  query aboutIceland(
    $uid: String!,
    $lang: String!
  ) {
    prismic {
    allAbout_icelands(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            uid
            lang
            alternateLanguages {
              id
              uid
              type
              lang
            }
          }
          title
          subtitle
          description
          hero_align
          image
          image_author
          image_caption
          seo_title
          seo_description
          seo_image
          video {
            _linkType
            __typename
            ... on PRISMIC__FileLink {
              url
              name
              size
            }
          }
          filters {
            item {
              _linkType
              ... on PRISMIC_Article_category {
                title
                _meta {
                  id
                  uid
                }
              }
            }
          }
          body {
            __typename
            ... on PRISMIC_About_icelandBodyCta_carousel {
              type
              fields {
                cta_carousel_image
                cta_carousel_kicker
                cta_carousel_kicker_image
                cta_carousel_heading
                cta_carousel_text
                cta_carousel_link {
                  _linkType
                  __typename
                  ... on PRISMIC_Article {
                    _meta {
                      uid
                      lang
                    }
                    title
                  }
                  ... on PRISMIC_Iceland_academy {
                    _meta {
                      uid
                      lang
                    }
                    title
                  }
                  ... on PRISMIC_A_o_overview {
                    _meta {
                      uid
                      lang
                    }
                    title
                  }
                  ... on PRISMIC_Icelandic_pledge {
                    _meta {
                      uid
                      lang
                    }
                    title
                  }
                }
              }
            }
          }
          items {
            item: itemss {
              ... on PRISMIC_Article {
                title
                list_description
                category {
                  _linkType
                  __typename
                  ...on PRISMIC_Article_category {
                    _meta {
                      id
                      uid
                      lang
                      type
                    }
                  title
                  }
                }
                image
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Page_content {
                title
                list_description
                category {
                  _linkType
                  __typename
                  ...on PRISMIC_Article_category {
                    _meta {
                      id
                      uid
                      lang
                      type
                    }
                  title
                  }
                }
                image
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Festivals {
                title
                list_description
                category {
                  _linkType
                  __typename
                  ...on PRISMIC_Article_category {
                    _meta {
                      id
                      uid
                      lang
                      type
                    }
                  title
                  }
                }
                image
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Flights_to_iceland {
                title
                list_description
                category {
                  _linkType
                  __typename
                  ...on PRISMIC_Article_category {
                    _meta {
                      id
                      uid
                      lang
                      type
                    }
                  title
                  }
                }
                image
                _meta {
                  ...meta
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

aboutIcelandTemplate.fragments = [fragmentMeta, fragmentLink];

export default aboutIcelandTemplate;
